<template>
  <div class="tapped page">
    <div class="main-banner-csapolt" ref="scrollHeightBlock">
      <b-container>
        <b-row>
          <b-col cols="12" lg="10" offset-lg="1">
            <div class="logo-wrap">
              <img class="w-100" src="../assets/images/brand/soproni_COLOR_toltsukegyutt_white_text.png" alt="Soproni" />
            </div>         
            <p class="csapolt-title">Ha csapolt soproni van, minden van</p>             
          </b-col>
        </b-row>      
      </b-container>
      <button class="btn--scroll-down"  @click="scrollToNextBlock"></button>
    </div>
    <b-container>
      <b-row>
        <b-col cols="12" lg="10" offset-lg="1">
            <div class="tapped__lead" v-inview="'fade-up'">
              Ahogy a sörfőzésnek, úgy a sörcsapolásnak is megvan a maga művészete. Az alábbi videókból megtudhatod, hogy milyen lépésekre kell odafigyelni sörcsapolás közben, így mindig tudni fogod, hol kapsz tökéletesen csapolt Sopronit.
            </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="12" md="6" class="tapped__half-text tapped__half-text--left" v-inview="'fade-from-left'">
          <div class="num">1.</div>
          <div class="text">
            A POHÁRNAK MINDIG TISZTÁNAK ÉS HIDEGNEK KELL LENNIE A TÖKÉLETES CSAPOLÁS ÉRDEKÉBEN. EZÉRT KELL KIÖBLÍTENI CSAPOLÁS ELŐTT.
          </div>
        </b-col>
        <b-col cols="12" md="6" v-inview="'fade-from-right'">
          <Video 
            :staticVideoName="'edukacio_1_1080p.mp4'" 
            :staticVideo="true" 
            :video="null" 
            :needScrollBtn="false"
            :poster="'video-bg-1.jpg'"
          />
        </b-col>
      </b-row>
      <b-row no-gutters >        
        <b-col cols="12" md="6" v-inview="'fade-from-left'">
          <Video 
            :staticVideoName="'edukacio_2_1080p.mp4'" 
            :staticVideo="true" 
            :video="null" 
            :needScrollBtn="false"
            :poster="'video-bg-2.jpg'"
          />
        </b-col>
        <b-col cols="12" md="6" class="tapped__half-text tapped__half-text--right" v-inview="'fade-from-right'">
          <div class="num">2.</div>
          <div class="text">
            EL KELL ENGEDNI AZ ELSŐ PÁR CSEPP SÖRT, MAJD A POHARAT 45°-OS SZÖGBEN A CSAP ALÁ TARTANI, AMÍG A HAB EL NEM ÉRI A POHÁR SZÁJÁT. FONTOS, HOGY A SÖRCSAP VÉGE NEM ÉRHET HOZZÁ A POHÁRHOZ ÉS KÉSŐBB A HABKORONÁHOZ SE!
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters >
        <b-col cols="12" md="6" class="tapped__half-text tapped__half-text--left" v-inview="'fade-from-left'">
          <div class="num">3.</div>
          <div class="text">
            A SÖRALÁTÉT NEMCSAK ESZTÉTIKUS, DE HASZNOS IS: GARANTÁLJA, HOGY A LECSAPÓDOTT PÁRA NEM ÁZTATJA EL AZ ASZTALUNKAT.
          </div>
        </b-col>
        <b-col cols="12" md="6" v-inview="'fade-from-right'">
          <Video 
            :staticVideoName="'edukacio_3_1080p.mp4'" 
            :staticVideo="true" 
            :video="null" 
            :needScrollBtn="false"
            :poster="'video-bg-3.jpg'"
          />
        </b-col>
      </b-row>     
    </b-container>
    <!-- <b-container v-inview="'fade-up'">
      <b-row>
        <b-col cols="12" lg="10" offset-lg="1" class="tapped__bottom">
          <p>MOST, HOGY MINDENT TUDSZ A SOPRONI CSAPOLÁSÁRÓL, TEDD PRÓBÁRA A TUDÁSOD!</p>
          <a href="https://www.soproni.hu/csapolt-kviz/" target="_blank" class="btn btn--green-border btn--with-arrow"><span> Csapolt kvíz</span></a>
        </b-col>
      </b-row>
    </b-container> -->    
  </div>
</template>

<script>
import Video from '@/components/layout/Video.vue'

export default {
  components:{
    Video
  },
  methods:{
    scrollToNextBlock(){            
        let scrollHeight = this.$refs.scrollHeightBlock.clientHeight

        window.scrollTo({
            left: 0, 
            top: scrollHeight,
            behavior: 'smooth'
        });                 
                    
    }
  },
  mounted(){
    this.setMetaTags({
        title: 'Soproni - Csapolt Kisokos', 
        desc: null, 
        keywords: null, 
        ogType: 'website', 
        ogTitle: 'Soproni - Csapolt Kisokos',                         
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  }
}
</script>